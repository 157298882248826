.rcw-widget-container {
    left: 2px;
    right: unset;
}
.rcw-launcher, .rcw-launcher, .rcw-open-launcher, .rcw-close-launcher {
    background-color: #639cd4;
}
.rcw-header .rcw-close-button {
    background-color: #639cd4;
}

.rcw-client .rcw-message-text {
    background-color: #639BD3;
    color: #fff;
    white-space: normal;
}

.rcw-response .rcw-message-text {
    background-color: #2C5088;
    color: #fff;
    white-space: normal;
}

.rcw-message-text p {
    color: #fff
}

.rcw-message-text p a {
    color: #a3eaf7;
}

.rcw-timestamp {
    color: #a2aaad;
    font-weight: bold;
}

.rcw-picker-btn {
    display: none;
}

.rcw-conversation-container .rcw-header, .rcw-conversation-container .rcw-close {
    background-color: #639cd4;
    color: #fff;
    padding: 5px 0 15px;
    font-weight: bolder;
}

.rcw-widget-container .quick-button {
    border: 2px solid #639cd4;
}

.rcw-conversation-container .avatar {
	width: 30%;
	height: unset;
	margin-right: 10px;
	vertical-align: middle;
	border: 2px solid #639cd4;
	background-color: white;
	padding: 5px;
	border-radius: 5px;
	min-width: 100px;
}

.rcw-title {
    background-color: #639cd4;
    color: #fff;
}